import React, { FC } from 'react';
import styles from './index.module.scss';
import { Row, Col, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListRadio } from '@fortawesome/pro-light-svg-icons';
import classnames from 'classnames';
import _ from 'lodash';
import Truncate from 'react-truncate';

// Components
import DateTime from '../Cards/CardsDateTime/CardsDateTime';
import CardsImage from '../Cards/CardsImage/CardsImage';


// Libraries
import sessionsCount from '../../libs/sessionsCount/sessionsCount';
import CardsCompanyLogo from '../Cards/CardsCompanyLogo/CardsCompanyLogo';
import gridLink from '../../libs/gridLink/gridLink';

export interface SectionFeaturedProps {
  summitData: SummitsArray[];
  type: 'summits' | 'series';
  sectionTitle: 'Summit' | 'Series'
  utmParams?: string;
  dataBddTitle?: string;
  dataBddDesc?: string;
  dataBddLearnMore?: string;
  siteLink: string;
  priorityStatus?: boolean;
}

export interface SummitsArray {
  id: number;
  title: string;
  description: string;
  scheduledStartDate: Date;
  scheduledEndDate: Date;
  visibility: string;
  imageUrl: string;
  companyLogo: string;
  primaryColor: string;
  secondaryColor: string;
  tracks: Track[];
  featured: boolean;
}

export interface Track {
  id: number;
  name: string;
  communications: Communication[];
}

export interface Communication {
  channelId: number;
  communicationId: number;
}

const SectionFeatured: FC<SectionFeaturedProps> = ({
  summitData,
  type,
  sectionTitle,
  utmParams,
  dataBddTitle,
  dataBddDesc,
  dataBddLearnMore,
  siteLink,
  priorityStatus
}) => {
  return (
    <div className={classnames(styles['bt-section-featured'], 'generic-featured')}>
      <Row>
        <Col md="6" lg="4" className={styles['featured-left']}>
          <div
            className={classnames(
              styles['img-wrap'],
              styles['section-featured-img-holder']
            )}
          >
            <a
              href={gridLink(
                summitData['wordpressLink'],
                summitData['id'],
                type,
                utmParams,
                'search-featured-section',
                0,
				siteLink
              )}
            >
              <CardsImage
                id={summitData['id']}
                href={summitData['imageUrl']}
                title={summitData['title']}
                pageType='section-featured'
                priorityStatus={priorityStatus}
              />
            </a>
          </div>
        </Col>
        <Col md="6" lg="8" className={styles['featured-right']}>
          <Row>
            <Col
              className={
                type === 'series' && styles['bt-section-featured-body']
              }
            >
              <h2 className={styles['featured-section-title']}>Latest { sectionTitle }</h2>
              <h3 data-bdd={dataBddTitle} className={styles['latest-webcast-title']}><a href={gridLink(
                summitData['wordpressLink'],
                summitData['id'],
                type,
                utmParams,
                'search-featured-section',
                0,
				siteLink
              )}>{summitData['title']}</a></h3>
              <p className={styles['item-meta']}>
                {type === 'summits' && (
                  <DateTime
                    dateTime={summitData['scheduledStartDate']}
                    endDate={summitData['scheduledEndDate']}
                    fromType="summit"
                  />
                )}
                {type === 'summits' ? (
                 <>
                  <span className={styles['text-divider']}>|</span><span>{sessionsCount(summitData['tracks'])} sessions</span>
                 </>
                ) : (
                  <span>
                    <FontAwesomeIcon icon={faListRadio} />{' '}
                    {sessionsCount(summitData['tracks'])} episodes
                  </span>
                )}
              </p>

               <p className={styles['description']} data-bdd={dataBddDesc}>
                <Truncate  lines={2}>
                 {summitData['description']}
                </Truncate>
               </p>

              <Button
                href={gridLink(
                  summitData['wordpressLink'],
                  summitData['id'],
                  type,
                  utmParams,
                  'search-featured-section',
                  0,
				  siteLink
                )}
                className={classnames(
                  styles['bt-button-regular-primary-short'],
                  styles['bt-elastic']
                )}
                data-bdd={dataBddLearnMore}
              >
                Learn more
              </Button>
            </Col>
            {type === 'series' && (
              <Col className={styles['company-logo-container']}>
                <div className={styles['company-logo-holder']}>
                  <p className={styles['series-feat-presented-by']}>Presented by</p>
                  <div className={styles['company-logo']}>
                    <CardsCompanyLogo
                      href={summitData['companyLogo']}
                      id={summitData['id']}
                      title={summitData['title']}
                      height={42}
                      width={105}
                      objectFit={'contain'}
                    />
                  </div>
                </div>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default SectionFeatured;
